/**
*
* site custom css
*/
/* variables */
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
/**
* Это основной стилевой файл
*/
.metadata-warning {
  font-weight: 300;
  width: 100%;
  min-width: 80vw;
  color: #fff;
  background-color: #909090;
  border-color: #88BD2F;
  position: relative;
  padding: .75rem 1.75rem .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  border-radius: 7px;
  margin: auto;
  display: block;
  z-index: 999;
}
.metadata-warning button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  outline: none;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: absolute;
  background: transparent;
  right: 10px;
  top: 5px;
  padding: 0;
  margin: 0;
  border: none;
}
.metadata-warning button:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 444;
  display: none;
}
.main-content {
  padding-top: 93px;
}
@media (min-width: 580px) and (max-width: 1024px) {
  .main-content {
    padding-top: 75px;
  }
}
@media (min-width: 320px) and (max-width: 579px) {
  .main-content {
    padding-top: 56px;
  }
}
.header-section {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1024px) {
  .header-section {
    display: none;
  }
}
.header__wrapper {
  align-items: stretch;
}
.header__logo {
  display: block;
  padding-top: 15px;
  padding-bottom: 17px;
}
.header__logo img {
  display: block;
  width: 169px;
  max-width: 169px;
}
.header__menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  height: 100%;
}
.header__menu_link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-decoration: none;
  text-align: center;
  border-bottom: 6px solid transparent;
  align-self: stretch;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  transition: border ease-in-out 0.1s;
}
@media (max-width: 1320px) {
  .header__menu_link {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.header__menu_link:hover {
  border-color: #88BD2F;
}
.header__feedback {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.header__feedback_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__feedback_callback {
  white-space: nowrap;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  max-height: 50px;
}
@media (max-width: 1320px) {
  .header__feedback_callback {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.header__feedback_call {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
@media (max-width: 1320px) {
  .header__feedback_call {
    margin-left: 10px;
  }
}
.header__feedback_call-caption {
  font-weight: 300;
}
.header__feedback_call-link {
  font-family: 'HelveticaNeue', Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
@media (max-width: 1320px) {
  .header__feedback_call-link {
    font-size: 16px;
  }
}
.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 666;
}
.header-mob-section {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1025px) {
  .header-mob-section {
    display: none;
  }
}
.header-mob__wrapper {
  align-items: stretch;
}
.header-mob__logo {
  display: block;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
}
@media (max-width: 579px) {
  .header-mob__logo_wrapper {
    width: auto;
    max-width: none;
    flex-basis: auto;
    margin-right: auto;
  }
}
.header-mob__logo img {
  display: block;
  width: 120px;
  max-width: 120px;
}
@media (max-width: 579px) {
  .header-mob__logo img {
    width: 75px;
    max-width: 75px;
  }
}
.header-mob__menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  display: none;
}
.header-mob__menu_wrapper {
  -webkit-box-shadow: inset 0 5px 3px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 5px 3px -3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 5px 3px -3px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}
.header-mob__menu_link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-decoration: none;
  text-align: center;
  border-bottom: 1px solid #dfdfdf;
  align-self: stretch;
  padding-left: 25px;
  padding-right: 25px;
}
.header-mob__menu_link:last-child {
  border-bottom: none;
}
.header-mob__menu_link svg {
  display: block;
  width: 16px;
  height: 16px;
  fill: #b3b3b3;
  transform: rotate(180deg);
  display: none;
}
@media (min-width: 1025px) and (max-width: 1320px) {
  .header-mob__menu_link {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .header-mob__menu_link {
    padding-top: 23px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 30px;
  }
}
.header-mob__feedback {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.header-mob__feedback_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 579px) {
  .header-mob__feedback_wrapper {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
}
.header-mob__feedback_callback {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  outline: none;
}
.header-mob__feedback_callback svg {
  display: block;
  width: 24px;
  height: 24px;
}
.header-mob__feedback_call {
  margin-right: 20px;
  text-align: right;
}
.header-mob__feedback_call-caption {
  font-weight: 300;
}
@media (max-width: 579px) {
  .header-mob__feedback_call-caption {
    font-size: 14px;
  }
}
.header-mob__feedback_call-link {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
@media (min-width: 580px) and (max-width: 1320px) {
  .header-mob__feedback_call-link {
    font-size: 16px;
  }
}
@media (min-width: 320px) and (max-width: 579px) {
  .header-mob__feedback_call-link {
    font-size: 14px;
  }
}
.header-mob__trigger {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 100%;
  background-color: #88BD2F;
  cursor: pointer;
}
@media (max-width: 579px) {
  .header-mob__trigger {
    width: 55px;
  }
}
.header-mob__trigger_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
}
.header-mob__trigger_icon {
  display: block;
  width: 24px;
  height: 24px;
}
.footer-section-top {
  position: relative;
  background-color: #646464;
  padding-top: 15px;
  padding-bottom: 10px;
}
.footer-section-top .up-button_link {
  position: absolute;
  top: 12px;
  right: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background-color: #88BD2F;
}
@media (min-width: 768px) {
  .footer-section-top .up-button_link {
    display: none;
  }
}
@media (max-width: 419px) {
  .footer-section-top .up-button_link {
    width: 30px;
    height: 30px;
  }
}
.footer-section-top .up-button_link svg {
  display: block;
  width: 20px;
  height: 20px;
  fill: #fff;
  transform: rotate(90deg);
}
@media (max-width: 419px) {
  .footer-section-top .up-button_link svg {
    width: 16px;
    height: 16px;
  }
}
.footer-section-bottom {
  font-weight: 300;
  color: #A2A2A2;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .footer-section-bottom {
    color: #fff;
    background-color: #333333;
    border-top: 1px solid #505050;
  }
}
.footer__contacts {
  font-weight: 300;
  color: #fff;
}
.footer__contacts a {
  color: #fff;
  text-decoration: none;
}
.footer__contacts a:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .footer__logo {
    display: none;
  }
}
.footer__logo img {
  display: block;
  width: 160px;
  max-width: 160px;
}
.footer__webdev.webmotor a {
  font-weight: 300;
  color: #A2A2A2;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .footer__webdev.webmotor a {
    color: #fff;
  }
}
.footer__webdev.webmotor a:hover {
  text-decoration: none;
}
.features-section {
  padding-top: 50px;
  padding-bottom: 30px;
}
.features__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
}
.features__item_icon {
  width: 55px;
  max-width: 55px;
  height: 55px;
  max-height: 55px;
  margin-bottom: 25px;
}
.features__item_icon img {
  width: 55px;
  max-width: 55px;
  height: 55px;
  max-height: 55px;
}
.features__item_caption {
  font-weight: 300;
  text-align: center;
}
.counter-section {
  padding-top: 50px;
  padding-bottom: 30px;
}
.counter__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
}
.counter__item_digit,
.counter__item_misc {
  font-size: 45px;
  font-weight: 700;
  color: #88BD2F;
}
.counter__item_misc {
  margin-left: 10px;
  margin-right: 10px;
}
.counter__item_caption {
  font-weight: 300;
  text-align: center;
}
.buildings-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.buildings-section h2 {
  margin-bottom: 40px;
}
.buildings__item {
  position: relative;
  overflow: hidden;
  max-width: 420px;
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.buildings__item_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity ease-in-out 0.1s;
}
@media (max-width: 1024px) {
  .buildings__item_wrapper {
    opacity: 1 !important;
  }
}
.buildings__item_inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buildings__item_logo {
  position: relative;
  width: 100%;
  height: 90px;
  margin-bottom: 35px;
}
.buildings__item_logo img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 255px;
}
.buildings__item_location {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}
.buildings__item_location-icon {
  width: 15px;
  height: 22px;
  margin-right: 10px;
}
.buildings__item_location-adress {
  color: #fff;
}
.buildings__item_link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.buildings__item_link svg {
  display: block;
  width: 22px;
  height: 22px;
  fill: #fff;
  margin-left: 5px;
  transition: margin ease-in-out 0.2s;
}
.buildings__item_link:hover svg {
  margin-left: 15px;
}
.buildings__item:hover .buildings__item_wrapper {
  opacity: 1;
}
.feedback-section > .container {
  max-width: unset;
}
.feedback__visual {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  height: 100%;
}
@media (max-width: 1023px) {
  .feedback__visual {
    height: 0;
  }
}
.feedback__visual_inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.feedback__form {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  color: #fff;
  background-color: #88BD2F;
  padding-top: 60px !important;
  padding-bottom: 55px !important;
}
@media (max-width: 1023px) {
  .feedback__form {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
.feedback__form_wrapper {
  max-width: 660px;
}
@media (min-width: 1201px) and (max-width: 1480px) {
  .feedback__form_wrapper {
    max-width: 87%;
  }
}
@media (min-width: 320px) and (max-width: 1200px) {
  .feedback__form_wrapper {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1023px) {
  .feedback__form_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
.feedback__form_wrapper .site-form {
  position: relative;
  padding-left: 10px;
}
@media (max-width: 1200px) {
  .feedback__form_wrapper .site-form {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 1023px) {
  .feedback__form_wrapper .site-form {
    padding-top: 10px;
  }
}
.feedback__form_wrapper .site-form label.error {
  position: relative;
  bottom: 0;
}
.feedback__form_wrapper .site-form .success {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  background-color: #88BD2F;
  z-index: 222;
  display: none;
}
@media (min-width: 580px) and (max-width: 1023px) {
  .feedback__form_wrapper .site-form .success {
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
  }
}
@media (max-width: 579px) {
  .feedback__form_wrapper .site-form .success {
    text-align: center;
  }
}
.feedback__form_wrapper .site-form .success svg {
  position: absolute;
  top: -45px;
  right: 10px;
  width: 20px;
  height: 20px;
  fill: #fff;
  cursor: pointer;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .feedback__form_wrapper .site-form .success svg {
    right: -5px;
  }
}
@media (min-width: 580px) and (max-width: 1023px) {
  .feedback__form_wrapper .site-form .success svg {
    top: -10px;
    right: 20px;
  }
}
@media (min-width: 320px) and (max-width: 579px) {
  .feedback__form_wrapper .site-form .success svg {
    top: -10px;
    right: 0;
  }
}
.feedback__form_wrapper .site-form-element-wrapper {
  margin-bottom: 35px;
}
.feedback__form_wrapper .site-form__head {
  color: #fff;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .feedback__form_wrapper .site-form__head {
    margin-bottom: 22px;
  }
}
.feedback__form_wrapper .site-form__label {
  position: absolute;
  top: 18px;
  left: 25px;
  display: block;
  width: auto;
  font-size: 16px;
  font-weight: 300;
  color: #afb0b1;
  transition: all ease-in-out 0.2s;
  z-index: 0;
}
.feedback__form_wrapper .site-form__label.top {
  top: -19px;
  left: 2px;
  color: #fff;
  font-size: 12px;
}
.feedback__form_wrapper .error:not(.js-masked-input):not(.site-form__input) {
  color: #fff !important;
  font-weight: 500 !important;
  font-style: italic !important;
}
.feedback__form_wrapper .site-form__input {
  font-family: 'CenturyGothic', Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 300;
  height: 60px;
  color: #afb0b1;
  background-color: #F3F3F3;
  border-color: #D6D6D6;
}
.feedback__form_wrapper .site-form__input::placeholder {
  color: #afb0b1;
}
.feedback__form_wrapper .site-form__input::-webkit-input-placeholder {
  color: #afb0b1;
}
.feedback__form_wrapper .site-form__input::-moz-placeholder {
  color: #afb0b1;
}
.feedback__form_wrapper .site-form__input:-moz-placeholder {
  color: #afb0b1;
}
.feedback__form_wrapper .site-form__input:-ms-input-placeholder {
  color: #afb0b1;
}
.feedback__form_wrapper .site-form__button-submit {
  width: 100%;
  font-family: 'CenturyGothic', Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border-width: 1px;
  border-color: #fff;
}
@media (max-width: 1200px) {
  .feedback__form_wrapper .site-form__button-submit {
    width: auto;
  }
}
.feedback__form_wrapper .site-form__agreement {
  font-weight: 300;
}
@media (max-width: 1023px) {
  .feedback__form_wrapper .site-form__agreement {
    font-size: 14px;
  }
}
.administration-section {
  padding-top: 30px;
  padding-bottom: 40px;
}
.administration__col {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
}
.administration__heading {
  text-align: center;
  margin-bottom: 35px;
}
.administration__item {
  position: relative;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 300px;
  overflow: hidden;
}
@media (min-width: 580px) and (max-width: 767px) {
  .administration__item {
    min-height: 500px;
  }
}
@media (min-width: 420px) and (max-width: 579px) {
  .administration__item {
    min-height: 300px;
  }
}
@media (min-width: 320px) and (max-width: 419px) {
  .administration__item {
    min-height: 200px;
  }
}
.administration__item_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
@media (max-width: 767px) {
  .administration__item_wrapper {
    background-position: center left;
  }
}
.administration__item_inner {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 111;
  transition: background ease-in-out 0.1s;
}
.administration__item_text {
  font-size: 25px;
  font-weight: 300;
  line-height: 1.5;
  color: #fff;
  text-shadow: 0 0 2px #969696;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 35px;
  padding-right: 35px;
  z-index: 222;
}
.administration__item_text.inverted {
  color: #000;
  transition: color ease-in-out 0.1s;
}
@media (max-width: 1200px) {
  .administration__item_text {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1023px) {
  .administration__item_text {
    font-size: 20px;
  }
}
.administration__item:hover .administration__item_inner {
  background-color: rgba(0, 0, 0, 0.3);
}
.administration__item:hover .administration__item_text.inverted {
  color: #fff;
}
.map__modal {
  height: 85vh;
}
.map__modal .mfp-close {
  top: -49px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #88BD2F;
}
.map__modal .mfp-close svg.icon {
  fill: #fff;
}
.map__modal .ymaps-2-1-66-balloon,
.map__modal .ymaps-2-1-67-balloon,
.map__modal .ymaps-2-1-68-balloon,
.map__modal .ymaps-2-1-69-balloon,
.map__modal .ymaps-2-1-70-balloon,
.map__modal .ymaps-2-1-71-balloon {
  box-shadow: none;
}
.map__modal .ymaps-2-1-66-balloon__layout,
.map__modal .ymaps-2-1-67-balloon__layout,
.map__modal .ymaps-2-1-68-balloon__layout,
.map__modal .ymaps-2-1-69-balloon__layout,
.map__modal .ymaps-2-1-70-balloon__layout,
.map__modal .ymaps-2-1-71-balloon__layout {
  background: #ffffff;
}
.map__modal .ymaps-2-1-66-balloon__content,
.map__modal .ymaps-2-1-67-balloon__content,
.map__modal .ymaps-2-1-68-balloon__content,
.map__modal .ymaps-2-1-69-balloon__content,
.map__modal .ymaps-2-1-70-balloon__content,
.map__modal .ymaps-2-1-71-balloon__content {
  background: transparent;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  min-width: 200px;
}
.map__modal .ymaps-2-1-66-balloon__tail,
.map__modal .ymaps-2-1-67-balloon__tail,
.map__modal .ymaps-2-1-68-balloon__tail,
.map__modal .ymaps-2-1-69-balloon__tail,
.map__modal .ymaps-2-1-70-balloon__tail,
.map__modal .ymaps-2-1-71-balloon__tail {
  display: none;
}
.map__modal .ymaps-2-1-66-balloon__close-button,
.map__modal .ymaps-2-1-67-balloon__close-button,
.map__modal .ymaps-2-1-68-balloon__close-button,
.map__modal .ymaps-2-1-69-balloon__close-button,
.map__modal .ymaps-2-1-70-balloon__close-button,
.map__modal .ymaps-2-1-71-balloon__close-button {
  width: 26px;
  height: 26px;
}
.map__modal .ymaps-2-1-66-balloon__content,
.map__modal .ymaps-2-1-67-balloon__content,
.map__modal .ymaps-2-1-68-balloon__content,
.map__modal .ymaps-2-1-69-balloon__content,
.map__modal .ymaps-2-1-70-balloon__content,
.map__modal .ymaps-2-1-71-balloon__content {
  font-family: 'CenturyGothic', Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
}
.map__modal .ymaps-2-1-66-copyrights-pane,
.map__modal .ymaps-2-1-67-copyrights-pane,
.map__modal .ymaps-2-1-68-copyrights-pane,
.map__modal .ymaps-2-1-69-copyrights-pane,
.map__modal .ymaps-2-1-70-copyrights-pane,
.map__modal .ymaps-2-1-71-copyrights-pane {
  display: none;
}
.map__modal .ymaps-2-1-64-ground-pane,
.map__modal .ymaps-2-1-66-ground-pane,
.map__modal .ymaps-2-1-67-ground-pane,
.map__modal .ymaps-2-1-68-ground-pane,
.map__modal .ymaps-2-1-69-ground-pane,
.map__modal .ymaps-2-1-70-ground-pane {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__modal .graphics-canvas {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__modal canvas {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__modal canvas:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
}
.map__full {
  height: 650px;
}
.map__full .ymaps-2-1-66-balloon,
.map__full .ymaps-2-1-67-balloon,
.map__full .ymaps-2-1-68-balloon,
.map__full .ymaps-2-1-69-balloon,
.map__full .ymaps-2-1-70-balloon,
.map__full .ymaps-2-1-71-balloon {
  box-shadow: none;
}
.map__full .ymaps-2-1-66-balloon__layout,
.map__full .ymaps-2-1-67-balloon__layout,
.map__full .ymaps-2-1-68-balloon__layout,
.map__full .ymaps-2-1-69-balloon__layout,
.map__full .ymaps-2-1-70-balloon__layout,
.map__full .ymaps-2-1-71-balloon__layout {
  background: #ffffff;
}
.map__full .ymaps-2-1-67-controls__control_toolbar,
.map__full .ymaps-2-1-66-controls__control_toolbar,
.map__full .ymaps-2-1-67-controls__control_toolbar,
.map__full .ymaps-2-1-68-controls__control_toolbar,
.map__full .ymaps-2-1-69-controls__control_toolbar,
.map__full .ymaps-2-1-70-controls__control_toolbar {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__full .ymaps-2-1-66-copyrights-pane,
.map__full .ymaps-2-1-67-copyrights-pane,
.map__full .ymaps-2-1-68-copyrights-pane,
.map__full .ymaps-2-1-69-copyrights-pane,
.map__full .ymaps-2-1-70-copyrights-pane,
.map__full .ymaps-2-1-71-copyrights-pane {
  display: none;
}
.map__full .ymaps-2-1-66-balloon__content,
.map__full .ymaps-2-1-67-balloon__content,
.map__full .ymaps-2-1-68-balloon__content,
.map__full .ymaps-2-1-69-balloon__content,
.map__full .ymaps-2-1-70-balloon__content,
.map__full .ymaps-2-1-71-balloon__content {
  background: transparent;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  min-width: 200px;
}
.map__full .ymaps-2-1-66-balloon__tail,
.map__full .ymaps-2-1-67-balloon__tail,
.map__full .ymaps-2-1-68-balloon__tail,
.map__full .ymaps-2-1-69-balloon__tail,
.map__full .ymaps-2-1-70-balloon__tail {
  display: none;
}
.map__full .ymaps-2-1-66-balloon__close-button,
.map__full .ymaps-2-1-67-balloon__close-button,
.map__full .ymaps-2-1-68-balloon__close-button,
.map__full .ymaps-2-1-69-balloon__close-button,
.map__full .ymaps-2-1-70-balloon__close-button {
  width: 26px;
  height: 26px;
}
.map__full .ymaps-2-1-66-balloon__content,
.map__full .ymaps-2-1-67-balloon__content,
.map__full .ymaps-2-1-68-balloon__content,
.map__full .ymaps-2-1-69-balloon__content,
.map__full .ymaps-2-1-70-balloon__content {
  font-family: 'CenturyGothic', Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
}
.map__full .ymaps-2-1-66-copyrights-pane,
.map__full .ymaps-2-1-67-copyrights-pane,
.map__full .ymaps-2-1-68-copyrights-pane,
.map__full .ymaps-2-1-69-copyrights-pane,
.map__full .ymaps-2-1-70-copyrights-pane {
  display: none;
}
.map__full .ymaps-2-1-64-ground-pane,
.map__full .ymaps-2-1-66-ground-pane,
.map__full .ymaps-2-1-67-ground-pane,
.map__full .ymaps-2-1-68-ground-pane,
.map__full .ymaps-2-1-69-ground-pane,
.map__full .ymaps-2-1-70-ground-pane {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__full .graphics-canvas {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__full canvas {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.map__full canvas:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
}
.rent-section {
  padding-top: 40px;
  padding-bottom: 47px;
}
.poster-section {
  border-bottom: 2px solid #bfbfbf;
  overflow-x: hidden;
}
.poster-section > .container {
  max-width: none;
}
.poster__wrapper {
  position: relative;
  width: 100%;
  height: 650px;
}
@media (max-width: 1024px) {
  .poster__wrapper {
    height: 450px;
  }
}
.poster__controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  padding-left: 45px;
  padding-right: 45px;
  z-index: 444;
}
@media (max-width: 767px) {
  .poster__controls {
    display: none;
  }
}
.poster__controls_inner {
  position: relative;
  height: 45px;
}
.poster__controls_prev,
.poster__controls_next {
  position: absolute;
  top: 0;
  width: 25px;
  height: 45px;
  cursor: pointer;
}
.poster__controls_prev svg,
.poster__controls_next svg {
  width: 25px;
  height: 45px;
  fill: #e8e4e1;
}
.poster__controls_prev {
  left: 0;
}
@media (max-width: 1024px) {
  .poster__controls_prev {
    left: -30px;
  }
}
.poster__controls_next {
  right: 0;
  transform: rotate(180deg);
}
@media (max-width: 1024px) {
  .poster__controls_next {
    right: -30px;
  }
}
.poster__inner {
  height: 650px;
}
@media (max-width: 1024px) {
  .poster__inner {
    height: 450px;
  }
}
.poster__inner .slick-dots {
  bottom: 20px;
}
.poster__inner .slick-dots button {
  position: relative;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
}
.poster__inner .slick-dots button:before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: transparent;
}
.poster__inner .slick-dots .slick-active button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #88BD2F;
}
.poster__item {
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.poster__item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
@media (max-width: 1024px) {
  .poster__item {
    height: 450px;
  }
}
.poster__item_content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  text-align: center;
  z-index: 555;
}
.poster__item_heading {
  font-size: 55px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  padding-top: 45px;
  padding-bottom: 45px;
}
@media (min-width: 1025px) and (max-width: 1199px) {
  .poster__item_heading {
    font-size: 44px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .poster__item_heading {
    font-size: 36px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .poster__item_heading {
    font-size: 24px;
  }
}
body.ie .objects__controls,
body.ie .loyality__controls {
  justify-content: flex-start;
}
body.ie .feedback__form_wrapper {
  width: 100%;
}
body.ie .fancybox-wrap {
  top: 50% !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-50%) !important;
  width: 70vw !important;
  height: 70vh !important;
}
body.ie .fancybox-skin {
  height: 100% !important;
}
body.ie .fancybox-outer,
body.ie .fancybox-inner {
  width: 100% !important;
  height: 100% !important;
}
body.ipad .header-mob__trigger {
  height: 75px;
}
@media screen and (min-width: 1020px) and (max-width: 1024px) and (orientation: landscape) {
  body.ipad .feedback__visual_inner {
    height: 525px;
  }
}
.g-recaptcha {
  display: none !important;
}
.grecaptcha-badge {
  display: none !important;
}
#service .loyality__controls_conditions {
  border-color: #E22026;
  background-color: #E22026;
  padding-left: 75px;
  padding-right: 75px;
}
#service .loyality__controls_conditions:hover {
  border-color: #D02026;
  background-color: #D02026;
}
/**
* файл для стилей для разных устройств
*/
