// metadata start
.metadata-warning {
    font-weight: 300;
    width: 100%;
    min-width: 80vw;
    color: #fff;
    // background-color: @primary-color;
    background-color: #909090;
    border-color: @primary-color;
    position: relative;
    padding: .75rem 1.75rem .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    position: fixed;
    left: 50%;
    transform:translateX(-50%);
    bottom: 20px;
    border-radius: 7px;
    margin: auto;
    display: block;
    z-index: 999;
    button {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      outline: none;
      color: #fff;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
      position: absolute;
      background: transparent;
      right: 10px;
      top: 5px;
      padding: 0;
      margin: 0;
      border: none;
      &:hover {
        color: #000;
        text-decoration: none;
        opacity: 0.75;
      }
  }
}
// metadata stop

// base start

.overlay {

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0,0,0,0.5);

  z-index: 444;

  display: none;

}

.main-content {
  padding-top: 93px;

  @media (min-width: 580px) and (max-width: 1024px) {
    
    padding-top: 75px;

  }

  @media (min-width: 320px) and (max-width: 579px) {

    padding-top: 56px;

  }

}

// base stop

// header start

.header {

  &-section {
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);

    @media (max-width: 1024px) {

      display: none;

    }
  }

  &__wrapper {
    align-items: stretch; 
  }

  &__logo {

    display: block;

    padding-top: 15px;
    padding-bottom: 17px;

    & img {

      display: block;

      width: 169px;
      max-width: 169px;
      
    }

  }

  &__menu {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    height: 100%;

    &_link {
      
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      
      font-weight: 300;
      line-height: 1;
      color: #000;
      text-decoration: none;
      text-align: center;

      border-bottom: 6px solid transparent;

      align-self: stretch;
      
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;

      transition: border ease-in-out 0.1s;

      @media (max-width: 1320px) {
        padding-left: 5px;
        padding-right: 5px;
      }

      &:hover {
        border-color: @primary-color;
      }
    }

  }

  &__feedback {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    &_wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &_callback {

      white-space: nowrap;

      padding-top: 14px !important;
      padding-bottom: 14px !important;

      max-height: 50px;

      @media (max-width: 1320px) {
        padding-left: 10px;
        padding-right: 10px;
      }

    }

    &_call {
      
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      margin-left: 30px;

      @media (max-width: 1320px) {
        margin-left: 10px;
      }

        &-caption {
          font-weight: 300;
        }

        &-link {
          font-family: @second-font;
          font-size: 18px;
          font-weight: 700;
          color: #000;
          text-decoration: none;

          @media (max-width: 1320px) {
            font-size: 16px;
          }
        }

    }

  }

}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 666;
}

.header-mob {

  &-section {
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);

    @media (min-width: 1025px) {

      display: none;

    }
  }

  &__wrapper {

    align-items: stretch; 

  }

  &__logo {

    display: block;

    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 10px;

    &_wrapper {

      @media (max-width: 579px) {

        width: auto;
        max-width: none;
        flex-basis: auto;
        margin-right: auto;

      }

    }

    & img {

      display: block;

      width: 120px;
      max-width: 120px;

      @media (max-width: 579px) {

        width: 75px;
        max-width: 75px;

      }
      
    }

  }

  &__menu {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;


    height: 100%;

    display: none;

    &_wrapper {

      -webkit-box-shadow: inset 0 5px 3px -3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0 5px 3px -3px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 5px 3px -3px rgba(0, 0, 0, 0.1);

      background-color: #fafafa;

    }

    &_link {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      
      font-weight: 300;
      line-height: 1;
      color: #000;
      text-decoration: none;
      text-align: center;

      border-bottom: 1px solid #dfdfdf;

      align-self: stretch;

      padding-left: 25px;
      padding-right: 25px;

      &:last-child {

        border-bottom: none;

      }

      & svg {

        display: block;
        
        width: 16px;
        height: 16px;

        fill: #b3b3b3;

        transform: rotate(180deg);

        display: none;

      }

      @media (min-width: 1025px) and (max-width: 1320px) {

        padding-left: 5px;
        padding-right: 5px;

      }

      @media (min-width: 320px) and (max-width: 1024px) {
        
        padding-top: 23px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 30px;

      }

    }

  }

  &__feedback {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    // width: 75px;
    width: auto;
    height: 100%;

    justify-content: center;
    align-items: center;

    &_wrapper {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-end;
      align-items: center;


      @media (max-width: 579px) {
        
        width: auto;
        max-width: none;
        flex-basis: auto;

      }

    }

    &_callback {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      outline: none;
      
      & svg {

        display: block;

        width: 24px;
        height: 24px;

      }

    }

    &_call {

      margin-right: 20px;
      text-align: right;

        &-caption {
          font-weight: 300;

          @media (max-width: 579px) {

            font-size: 14px;

          }

        }

        &-link {
          font-size: 18px;
          font-weight: 700;
          color: #000;
          text-decoration: none;

          @media (min-width: 580px) and (max-width: 1320px) {
            font-size: 16px;
          }
          @media (min-width: 320px) and (max-width: 579px) {
            
            font-size: 14px;

          }
        }

    }

  }

  &__trigger {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 75px;
    height: 100%;

    background-color: @primary-color;

    cursor: pointer;

    @media (max-width: 579px) {

      width: 55px;

    }

    &_wrapper {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;

      justify-content: flex-end;

    }

    &_icon {

      display: block;

      width: 24px;
      height: 24px;

      &.menu {

        

      }

      &.close {

        

      }

    }

  }

}
// header stop

// footer start

.footer {

  &-section {
    
    &-top {

      position: relative;

      background-color: #646464;

      padding-top: 15px;
      padding-bottom: 10px;

      & .up-button_link {

        position: absolute;
        top: 12px;
        right: 10px;

        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 52px;
        height: 52px;

        background-color: @primary-color;

        @media (min-width: 768px) {
          
          display: none;

        }

        @media (max-width: 419px) {

          width: 30px;
          height: 30px;

        }

        & svg {

          display: block;
          width: 20px;
          height: 20px;
          fill: #fff;
          transform: rotate(90deg);

          @media (max-width: 419px) {

            width: 16px;
            height: 16px;

          }

        }

      }

    }

    &-bottom {
      
      font-weight: 300;
      color: #A2A2A2;
      
      padding-top: 10px;
      padding-bottom: 10px;

      @media (max-width: 767px) {
        
        color: #fff;
        background-color: #333333;
        border-top: 1px solid #505050;

      }

    }

  }

  &__contacts {
    
    font-weight: 300;
    color: #fff;

    & a {

      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

    }

  }

  &__logo {

    @media (max-width: 767px) {
      
      display: none;

    }

    & img {
      display: block;

      width: 160px;
      max-width: 160px;
    }

  }

  &__copyright {
    
  }

  &__webdev.webmotor {

    a {
      font-weight: 300;
      color: #A2A2A2;
      text-decoration: underline;

      @media (max-width: 767px) {
        
        color: #fff;

      }

      &:hover {
        text-decoration: none;
      }
    }
    
  }

}

// footer stop

// features start

.features {

  &-section {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__item {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 35px;
    margin-bottom: 35px;

    &_icon {

      width: 55px;
      max-width: 55px;
      height: 55px;
      max-height: 55px;
      margin-bottom: 25px;

      & img {

        width: 55px;
        max-width: 55px;
        height: 55px;
        max-height: 55px;


      }

    }

    &_caption {
      font-weight: 300;
      text-align: center;
    }

  }

}

// features stop

// counter start

.counter {

  &-section {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__item {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 35px;
    margin-bottom: 35px;

    &_digit, &_misc {

      font-size: 45px;
      font-weight: 700;
      color: @primary-color;

    }

    &_misc {
      margin-left: 10px;
      margin-right: 10px;
    }

    &_caption {
      font-weight: 300;
      text-align: center;
    }

  }

}

// counter stop

// buildings start

.buildings {

  &-section {
    padding-top: 50px;
    padding-bottom: 50px;

    & h2 {
      margin-bottom: 40px;
    }
  }

  &__item {

    position: relative;
    overflow: hidden;
    
    max-width: 420px;
    height: 550px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    &_wrapper {

      position: absolute;

      width: 100%;
      height: 100%;

      background-color: rgba(0,0,0,0.4);

      opacity: 0;

      transition: opacity ease-in-out 0.1s;

      @media (max-width: 1024px) {
      
        opacity: 1 !important;

      }

    }

    &_inner {
      position: relative;

      width: 100%;
      height: 100%;

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &_logo {

      position: relative;
      width: 100%;
      height: 90px;

      margin-bottom: 35px;

      & img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);        

        width: 255px;
      }

    }

    &_location {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 35px;
      
      &-icon {
        width: 15px;
        height: 22px;
        margin-right: 10px;
      }

      &-adress {
        color: #fff;
      }

    }

    &_link {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      & svg {
        display: block;
        width: 22px;
        height: 22px;
        fill: #fff;
        margin-left: 5px;
        transition: margin ease-in-out 0.2s;
      }
      &:hover {
        & svg {
          margin-left: 15px;
        }
      }

    }

    &:hover .buildings__item_wrapper {
      opacity: 1;
    }

  }

}

// buildings stop

// feedback start

.feedback {

  &-section {

    & > .container {

      max-width: unset;

    }

  }

  &__visual {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    width: 100%;
    height: 100%;

    @media (max-width: 1023px) {

      height: 0;

    }

    &_inner {

      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;

    }

  }

  &__form {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end;
    
    color: #fff;
    background-color: @primary-color;

    padding-top: 60px !important;
    padding-bottom: 55px !important;

    @media (max-width: 1023px) {

      padding-top: 20px !important;
      padding-bottom: 20px !important;

    }

    &_wrapper {

      max-width: 660px;

      @media (min-width: 1201px) and (max-width: 1480px) {

        max-width: 87%;

      }

      @media (min-width: 320px) and (max-width: 1200px) {

        max-width: 100%;

        padding-left: 20px;

        padding-right: 20px;

      }

      @media (max-width: 1023px) {

        margin-left: auto;
        margin-right: auto;

      }

      & .site-form {

        position: relative;

        padding-left: 10px;
        
        @media (max-width: 1200px) {

          padding-right: 10px;
          padding-left: 10px;

        }

        @media (max-width: 1023px) {

          padding-top: 10px;

        }

        & label.error {

          position: relative;
          bottom: 0;

        }

        & .success {

          position: absolute;
          top: 0;
          left: 0;

          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          height: 100%;
          
          font-size: 22px;
          font-weight: 300;

          color: #fff;
          background-color: @primary-color;

          z-index: 222;

          display: none;

          @media (min-width: 580px) and (max-width: 1023px) {

            left: 50%;
            transform: translateX(-50%);

            width: 100vw;

          }

          @media (max-width: 579px) {

            text-align: center;

          }

          & svg {

            position: absolute;

            top: -45px;
            right: 10px;

            width: 20px;
            height: 20px;

            fill: #fff;

            cursor: pointer;

            @media (min-width: 1024px) and (max-width: 1200px) {

              right: -5px;

            }

            @media (min-width: 580px) and (max-width: 1023px) {

              top: -10px;
              right: 20px;

            }

            @media (min-width: 320px) and (max-width: 579px) {

              top: -10px;
              right: 0;

            }

          }

        }

      }

      & .site-form-element-wrapper {
        
        margin-bottom: 35px;

      }

      & .site-form__head {

        color: #fff;
        margin-bottom: 40px;

        @media (max-width: 767px) {

          margin-bottom: 22px;

        }

      }

      & .site-form__label {

        position: absolute;
        top: 18px;
        left: 25px;

        display: block;

        width: auto;

        font-size: 16px;
        font-weight: 300;
        color: #afb0b1;

        transition: all ease-in-out 0.2s;
        z-index: 0;

        &.top {

          top: -19px;
          left: 2px;
          color: #fff;
          font-size: 12px;

        }

      }

      & .error:not(.js-masked-input):not(.site-form__input) {

        color: #fff !important;
        font-weight: 500 !important;
        font-style: italic !important;
      }

      & .site-form__input {

        font-family: @main-font;
        font-weight: 300;
        
        height: 60px;

        color: #afb0b1;
        background-color: #F3F3F3;
        border-color: #D6D6D6;

        &::placeholder {
          color: #afb0b1;
        }
        &::-webkit-input-placeholder {
          color: #afb0b1;
        }
        &::-moz-placeholder {
          color: #afb0b1;
        }
        &:-moz-placeholder {
          color: #afb0b1;
        }
        &:-ms-input-placeholder {
          color: #afb0b1;
        }
      }

      & .site-form__button-submit {

        width: 100%;

        font-family: @main-font;
        font-weight: 700;

        color: #000;
        background-color: #fff;

        border-width: 1px;
        border-color: #fff;

        @media (max-width: 1200px) {

          width: auto;

        }

      }

      & .site-form__agreement {
        font-weight: 300;

        @media (max-width: 1023px) {

          font-size: 14px;

        }
      }

    }

  }

}

// feedback stop

// administration start

.administration {

  &-section {

    padding-top: 30px;
    padding-bottom: 40px;

  }

  &__col {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;

  }

  &__heading {

    text-align: center;
    margin-bottom: 35px;

  }

  &__item {

    position: relative;

    margin-bottom: 20px;
    
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;
    min-height: 300px;

    overflow: hidden;

    @media (min-width: 580px) and (max-width: 767px) {
      
      min-height: 500px;

    }

    @media (min-width: 420px) and (max-width: 579px) {

      min-height: 300px;

    }

    @media (min-width: 320px) and (max-width: 419px) {

      min-height: 200px;

    }

    &_wrapper {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      height: 100%;
      flex-grow: 1;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: top left;

      @media (max-width: 767px) {

        background-position: center left;

      }

    }

    &_inner {

      position: absolute;
      top: 0;
      left: 0;

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      
      width: 100%;
      height: 100%;
      flex-grow: 1;

      background-color: rgba(0,0,0,0.1);

      z-index: 111;

      transition: background ease-in-out 0.1s;
      
    }

    &_text {
      
      font-size: 25px;
      font-weight: 300;
      line-height: 1.5;
      color: #fff;

      text-shadow: 0 0 2px rgba(150, 150, 150, 1);
      
      padding-top: 26px;
      padding-bottom: 26px;
      padding-left: 35px;
      padding-right: 35px;

      z-index: 222;

      &.inverted {
        
        color: #000;

        transition: color ease-in-out 0.1s;

      }

      @media (max-width: 1200px) {
        
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;

      }

      @media (max-width: 1023px) {

        font-size: 20px;

      }

    }

    &:hover {
    
      & .administration__item_inner {

        background-color: rgba(0,0,0,0.3);

      }

      & .administration__item_text.inverted {

        color: #fff;

      }

    }

  }

}

// administration stop

// map start

.map {

  &__modal {

    height: 85vh;

    & .mfp-close {

      top: -49px; 

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      
      background-color: @primary-color;

      & svg.icon {

        fill: #fff;

      }

    }

    & .ymaps-2-1-66-balloon, & .ymaps-2-1-67-balloon, .ymaps-2-1-68-balloon, & .ymaps-2-1-69-balloon, .ymaps-2-1-70-balloon, & .ymaps-2-1-71-balloon {
      
      box-shadow: none;

    }

    & .ymaps-2-1-66-balloon__layout, & .ymaps-2-1-67-balloon__layout, .ymaps-2-1-68-balloon__layout, & .ymaps-2-1-69-balloon__layout, .ymaps-2-1-70-balloon__layout, & .ymaps-2-1-71-balloon__layout {

      background: rgba(255,255,255,1.0);

    }

    & .ymaps-2-1-66-balloon__content, & .ymaps-2-1-67-balloon__content, .ymaps-2-1-68-balloon__content, & .ymaps-2-1-69-balloon__content, .ymaps-2-1-70-balloon__content, & .ymaps-2-1-71-balloon__content {
      
      background: transparent;

      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 25px;

      min-width: 200px;

    }

    & .ymaps-2-1-66-balloon__tail, & .ymaps-2-1-67-balloon__tail, .ymaps-2-1-68-balloon__tail, & .ymaps-2-1-69-balloon__tail, .ymaps-2-1-70-balloon__tail, & .ymaps-2-1-71-balloon__tail {

      display: none;

    }

    & .ymaps-2-1-66-balloon__close-button, & .ymaps-2-1-67-balloon__close-button, .ymaps-2-1-68-balloon__close-button, & .ymaps-2-1-69-balloon__close-button, .ymaps-2-1-70-balloon__close-button, & .ymaps-2-1-71-balloon__close-button {

      width: 26px;
      height: 26px;

    }

    & .ymaps-2-1-66-balloon__content, & .ymaps-2-1-67-balloon__content, .ymaps-2-1-68-balloon__content, & .ymaps-2-1-69-balloon__content, .ymaps-2-1-70-balloon__content, & .ymaps-2-1-71-balloon__content {

      font-family: @main-font;
      font-weight: 300;
      font-size: 16px;

    }

    & .ymaps-2-1-66-copyrights-pane, & .ymaps-2-1-67-copyrights-pane, .ymaps-2-1-68-copyrights-pane, & .ymaps-2-1-69-copyrights-pane, .ymaps-2-1-70-copyrights-pane, & .ymaps-2-1-71-copyrights-pane {
      display: none;
    }

    & .ymaps-2-1-64-ground-pane, & .ymaps-2-1-66-ground-pane, & .ymaps-2-1-67-ground-pane, .ymaps-2-1-68-ground-pane, & .ymaps-2-1-69-ground-pane, & .ymaps-2-1-70-ground-pane {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    & .graphics-canvas {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    & canvas {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
      &:hover {
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(100%);
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
      }
    }

  }

  &__full {

    height: 650px;

    & .ymaps-2-1-66-balloon, & .ymaps-2-1-67-balloon, .ymaps-2-1-68-balloon, & .ymaps-2-1-69-balloon, .ymaps-2-1-70-balloon, & .ymaps-2-1-71-balloon {
      
      box-shadow: none;

    }

    & .ymaps-2-1-66-balloon__layout, & .ymaps-2-1-67-balloon__layout, .ymaps-2-1-68-balloon__layout, & .ymaps-2-1-69-balloon__layout, .ymaps-2-1-70-balloon__layout, & .ymaps-2-1-71-balloon__layout {

      background: rgba(255,255,255,1.0);

    }

    & .ymaps-2-1-67-controls__control_toolbar, & .ymaps-2-1-66-controls__control_toolbar, .ymaps-2-1-67-controls__control_toolbar, & .ymaps-2-1-68-controls__control_toolbar, .ymaps-2-1-69-controls__control_toolbar, & .ymaps-2-1-70-controls__control_toolbar {

      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);

    }

    & .ymaps-2-1-66-copyrights-pane, & .ymaps-2-1-67-copyrights-pane, .ymaps-2-1-68-copyrights-pane, & .ymaps-2-1-69-copyrights-pane, .ymaps-2-1-70-copyrights-pane, & .ymaps-2-1-71-copyrights-pane {
      
      display: none;

    }

    & .ymaps-2-1-66-balloon__content, & .ymaps-2-1-67-balloon__content, .ymaps-2-1-68-balloon__content, & .ymaps-2-1-69-balloon__content, .ymaps-2-1-70-balloon__content, & .ymaps-2-1-71-balloon__content {
      
      background: transparent;

      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 25px;

      min-width: 200px;

    }

    & .ymaps-2-1-66-balloon__tail, & .ymaps-2-1-67-balloon__tail, .ymaps-2-1-68-balloon__tail, & .ymaps-2-1-69-balloon__tail, & .ymaps-2-1-70-balloon__tail {

      display: none;

    }

    & .ymaps-2-1-66-balloon__close-button, .ymaps-2-1-67-balloon__close-button, .ymaps-2-1-68-balloon__close-button, & .ymaps-2-1-69-balloon__close-button, & .ymaps-2-1-70-balloon__close-button {

      width: 26px;
      height: 26px;

    }

    & .ymaps-2-1-66-balloon__content, & .ymaps-2-1-67-balloon__content, .ymaps-2-1-68-balloon__content, & .ymaps-2-1-69-balloon__content, & .ymaps-2-1-70-balloon__content {

      font-family: @main-font;
      font-weight: 300;
      font-size: 16px;

    }

    & .ymaps-2-1-66-copyrights-pane, & .ymaps-2-1-67-copyrights-pane, .ymaps-2-1-68-copyrights-pane, & .ymaps-2-1-69-copyrights-pane, & .ymaps-2-1-70-copyrights-pane {
      display: none;
    }

    & .ymaps-2-1-64-ground-pane, & .ymaps-2-1-66-ground-pane, & .ymaps-2-1-67-ground-pane, .ymaps-2-1-68-ground-pane, & .ymaps-2-1-69-ground-pane, & .ymaps-2-1-70-ground-pane, {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    & .graphics-canvas {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    & canvas {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
      &:hover {
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(100%);
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
      }
    }

  } 

}

// map stop

// rent start

.rent {

  &-section {
    
    padding-top: 40px;
    padding-bottom: 47px;

  }

}

// rent stop

// poster start

.poster {

  &-section {

    border-bottom: 2px solid #bfbfbf;

    overflow-x: hidden;

    & > .container {
      max-width: none;
    }

  }

  &__wrapper {

    position: relative;

    width: 100%;
    height: 650px;

    @media (max-width: 1024px) {
      
      height: 450px;

    }    

  }

  &__controls {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    width: 100%;

    padding-left: 45px;
    padding-right: 45px;

    z-index: 444;

    @media (max-width: 767px) {
      display: none;
    }

    &_inner {

      position: relative;

      height: 45px;

    }

    &_prev, &_next {

      position: absolute;
      top: 0;

      width: 25px;
      height: 45px;

      cursor: pointer;

      & svg {
        width: 25px;
        height: 45px;

        fill: #e8e4e1;
      }

    }

    &_prev {
  
      left: 0;

      @media (max-width: 1024px) {
        left: -30px;
      }

    }

    &_next {
      
      right: 0;
      transform: rotate(180deg);

      @media (max-width: 1024px) {
        right: -30px;
      }

    }

  }

  &__inner {

    height: 650px;

    @media (max-width: 1024px) {
      
      height: 450px;

    }

    & .slick-dots {

      bottom: 20px;

      & button {

        position: relative;

        width: 22px;
        height: 22px;

        border: 2px solid #fff;

        &:before {

          content: '';
          
          width: 10px;
          height: 10px;
          background-color: transparent;

        }

      }

      & .slick-active button:before {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        background-color: @primary-color;

      }

    }

  }

  &__item {

    height: 650px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before {

      content: '';

      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 100%;

      background-color: rgba(0,0,0,0.25);

    }

    @media (max-width: 1024px) {
      
      height: 450px;

    }

    &_content {

      position: relative;
      top: 50%;
      transform: translateY(-50%);

      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
      text-align: center;

      z-index: 555;

    }

    &_heading {

      font-size: 55px;
      font-weight: 700;
      line-height: 1.2;
      color: #fff;

      padding-top: 45px;
      padding-bottom: 45px;

      @media (min-width: 1025px) and (max-width: 1199px) {

        font-size: 44px;

      }

      @media (min-width: 768px) and (max-width: 1024px) {

        font-size: 36px;

      }

      @media (min-width: 320px) and (max-width: 767px) {
        
        font-size: 24px;
        
      }

    }

    &_btn {

      

    }

  }

}

// poster stop

// IE madness start

body.ie {

  & .objects__controls, & .loyality__controls, {
    justify-content: flex-start;
  }

  & .feedback__form_wrapper {
    width: 100%;
  }

  & .fancybox-wrap {

    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;

    width: 70vw !important;
    height: 70vh !important;

  }

  & .fancybox-skin {

    height: 100% !important;

  }

  & .fancybox-outer, & .fancybox-inner {
    
    width: 100% !important;
    height: 100% !important;

  }

}

// IE madness stop

// iPad madness start

body.ipad {

  & .header-mob__trigger {

    height: 75px;

  }

  @media screen and (min-width: 1020px) and (max-width: 1024px) and (orientation: landscape) {

    & .feedback__visual_inner {

      height: 525px;

    }

  }

}

// iPad madness stop

// lol

.g-recaptcha {
  display: none !important;
}

.grecaptcha-badge {
  display: none !important;
}

#service .loyality__controls_conditions {
  border-color: #E22026;
  background-color: #E22026;
  padding-left: 75px;
  padding-right: 75px;
  &:hover {
    border-color: #D02026;
    background-color: #D02026;
  }
}
